import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import WorkList from '../../components/WorkList'

const HomeWorks = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allJavascriptFrontmatter(
          sort: { fields: [frontmatter___date], order: DESC },
          filter: {frontmatter: {published: {ne: false}}}
        ) {
          edges {
            node {
              ...WorkData
            }
          }
        }
      }
    `
  )

  return <WorkList works={query} />
}

export default HomeWorks
